define('carnival-frontend/components/settings/select-fcm', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'form',
    classNames: 'certificate-container',
    classNameBindings: ['serviceAccount.project_id:has-certificate'],
    serviceAccount: {},
    defaultErrorMsg: 'This is not a valid service account.',

    change: function change(event) {
      var file = event.target.files[0];

      if (!file) return;

      // assume single file selection only
      this.handleFileSelection(file);
    },
    click: function click() {
      this.$('input[type="file"]').val(null);
    },
    handleFileSelection: function handleFileSelection(file) {
      var that = this,
          reader = new FileReader(),
          fileName = file.name || file.fileName || '',
          fileExt = fileName.split('.').pop().toLowerCase(),
          model = this.get('model');

      model.save().then(function () {
        if (fileExt === 'json') {
          reader.onload = function () {
            that.save(reader.result);
          };
          reader.readAsText(file);
        } else {
          that.sendAction('alertError', 'Service Account Error: ', that.get('defaultErrorMsg'));
        }
      });
    },
    save: function save(serviceAccount) {
      var model = this.get('model'),
          that = this;

      model.saveServiceAccount(serviceAccount).then(function (response) {
        that.set('serviceAccount', response.fcm_service_account);
        that.sendAction('alertSuccess', 'Service Account: ', 'Uploaded and saved successfully.');
      }, function (response) {
        var errors = response.errors.errors || response.errors[0].detail || that.get('defaultErrorMsg'),
            message = errors.fcm_service_account || errors;

        that.sendAction('alertError', 'Service Account Error: ', S(message).humanize().s);
      });
    },


    actions: {
      removeServiceAccount: function removeServiceAccount() {
        console.log('Removing...');
        var model = this.get('model'),
            that = this;

        model.removeServiceAccount().then(function () {
          that.set('serviceAccount', {});
          that.sendAction('alertSuccess', 'Service Account: ', 'Removed successfully.');
        });
      }
    }
  });
});